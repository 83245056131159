import { IContactExtendedBE } from "@datalex-software-as/datalex-client";

export function CompareCode(a: any, b: any) {
  if (a.Code && b.Code) {
    if (a.Code.toLowerCase() < b.Code.toLowerCase()) {
      return -1;
    }
    if (a.Code.toLowerCase() > b.Code.toLowerCase()) {
      return 1;
    }
  }
  return 0;
}

export function CompareNumberAcending(a: any, b: any) {
  if (a.Number < b.Number) {
    return -1;
  }
  if (a.Number > b.Number) {
    return 1;
  }
  return 0;
}

export function CompareNumberDecending(a: any, b: any) {
  if (a.Number < b.Number) {
    return 1;
  }
  if (a.Number > b.Number) {
    return -1;
  }
  return 0;
}

/**
 * Compares the Name of IContactExtendedBE acending to array.sort
 * @param a IContactExtendedBE.
 * @param b IContactExtendedBE.
 */
export function CompareNameAcending(a: IContactExtendedBE, b: IContactExtendedBE) {
  if (a.Name && b.Name) {
    if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
      return -1;
    }
    if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
      return 1;
    }
  }
  return 0;
}
/**
 * Compares the Name of IContactExtendedBE decending to array.sort
 * @param a IContactExtendedBE.
 * @param b IContactExtendedBE.
 */
export function CompareNameDecending(a: IContactExtendedBE, b: IContactExtendedBE) {
  if (a.Name && b.Name) {
    if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
      return -1;
    }
    if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
      return 1;
    }
  }
  return 0;
}

/**
 * Compares the MobilePhoneNumber of IContactExtendedBE Decending with 'null' first to array.sort
 * @param a IContactExtendedBE.
 * @param b IContactExtendedBE.
 */
export function ComparePhoneExistingDecending(a: IContactExtendedBE, b: IContactExtendedBE) {
  if (a.PhoneNumberMobile && b.PhoneNumberMobile) {
    if (a.PhoneNumberMobile === null) {
      return 1;
    }
    if (b.PhoneNumberMobile === null) {
      return -1;
    }
    if (a.PhoneNumberMobile === b.PhoneNumberMobile) {
      return 0;
    }
  }
  return a < b ? -1 : 1;
}
/**
* Compares the MobilePhoneNumber of IContactExtendedBE Acending with 'last' first to array.sort
* @param a IContactExtendedBE.
* @param b IContactExtendedBE.
*/
export function ComparePhoneExistingAcending(a: IContactExtendedBE, b: IContactExtendedBE) {
  if (a.PhoneNumberMobile && b.PhoneNumberMobile) {
    if (a.PhoneNumberMobile === null) {
      return 1;
    }
    if (b.PhoneNumberMobile === null) {
      return -1;
    }
    if (a.PhoneNumberMobile === b.PhoneNumberMobile) {
      return 0;
    }
  }
  return a < b ? 1 : -1;
}

/**
 * Compares the Status of IContactExtendedBE Decending with 'null' first to array.sort
 * @param a IContactExtendedBE.
 * @param b IContactExtendedBE.
 */
export function CompareStatusExistingDecending(a: IContactExtendedBE, b: IContactExtendedBE) {
  if (a.Status && b.Status) {
    if (a.Status === null) {
      return 1;
    }
    if (b.Status === null) {
      return -1;
    }
    if (a.Status === b.Status) {
      return 0;
    }
  }
  return a < b ? -1 : 1;
}

/**
 * Compares the Status of IContactExtendedBE Decending with 'null' last to array.sort
 * @param a IContactExtendedBE.
 * @param b IContactExtendedBE.
 */
export function CompareStatusExistingAcending(a: IContactExtendedBE, b: IContactExtendedBE) {
  if (a.Status && b.Status) {
    if (a.Status === null) {
      return 1;
    }
    if (b.Status === null) {
      return -1;
    }
    if (a.Status === b.Status) {
      return 0;
    }
  }
  return a < b ? 1 : -1;
}